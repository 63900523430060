import React, { Component } from 'react'

class BlockQuote extends Component {
  render() {
    return (
      <div className='post-block post-block--blockquote'>
        <div className="post-block__blockquote" dangerouslySetInnerHTML={{__html: this.props.blockquote }} />
      </div>
    )
  }
}

export default BlockQuote
