import React, { Component } from 'react'
import he from 'he'
import axios from 'axios'

import { Twitter, Facebook, LinkedIn, Flipboard  } from './icons'

class ArticleMobileSocial extends Component {

  state = {
    shareLink: '',
  }

  componentDidMount() {
    this.setState({ shareLink: window.location.href })
  }

  render() {
    let { shareLink } = this.state
    const { title, url, wordpress_id } = this.props

    let props = {
      target: '_blank',
      rel: 'noopener noreferrer',
      className: 'article__mobile-social-share',
      onClick: (event) => {
        axios.post(`${url}/wp-json/tfh/v1/shares`, {
          ID: wordpress_id
        }).catch(e => console.log(e))
      },
    }

    return (
      <div className='article__mobile-social'>
        <h5>Share Article</h5>
        <div className='article__mobile-social-inner'>
          <a href={`//www.facebook.com/sharer/sharer.php?u=${shareLink}`} {...props}><Facebook /></a>
          <a href={`//twitter.com/home?status=${shareLink}`} {...props}><Twitter /></a>
          <a href={`//www.linkedin.com/shareArticle?mini=true&url=${shareLink}&title=${he.decode(title)}`} {...props}><LinkedIn /></a>
          <a href={`//share.flipboard.com/bookmarklet/popout?v=2&title=${he.decode(title)}&url=${shareLink}`} {...props}><Flipboard /></a>
        </div>
      </div>
    )
  }
}

export default ArticleMobileSocial
