import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import he from 'he'
import Helmet from 'react-helmet'
import axios from 'axios'

import Tag from '../components/tag'
import ArticleSocial from '../components/article-social'
import ArticleMobileSocial from '../components/article-mobile-social'
import Content from '../components/post/content'
import Image from '../components/post/image'
import Video from '../components/post/video'
import BlockQuote from '../components/post/blockquote'
import { Share } from '../components/icons'

const ARTICLE_LIMIT = 4
const DISTANCE_FROM_BOTTOM = 1000

const shuffle = function(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

class Article extends Component {

  scrollPos = 0
  ticking = false
  state = {
    articles: {
      initial: JSON.parse(JSON.stringify(shuffle(this.props.data.allWordpressPost.edges))),
      appended: [],
    },
    url: `${this.props.data.sitePlugin.pluginOptions.protocol}://${this.props.data.sitePlugin.pluginOptions.baseUrl}`,
  }

  renderBlock(param, el, i) {
    let block = {
      'WordPressAcf_content': (el, i) => (<Content {...el} key={i} />),
      'WordPressAcf_image': (el, i) => (<Image {...el} key={i} />),
      'WordPressAcf_video': (el, i) => (<Video {...el} key={i} />),
      'WordPressAcf_blockquote': (el, i) => (<BlockQuote {...el} id={i} key={i} />),
    }[param]

    if (!block) return

    return block(el, i)
  }

  componentDidMount() {
    axios.post(`${this.state.url}/wp-json/tfh/v1/counter`, {
      ID: this.props.data.wordpressPost.wordpress_id
    }).catch(e => console.log(e))

    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = (event) => {
    this.scrollPos = window.scrollY

    if (this.ticking) return

    window.requestAnimationFrame(() => {
      let body = document.body
      let html = document.documentElement
      let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
      let footer = DISTANCE_FROM_BOTTOM

      let nodes = document.querySelectorAll('.article')
      for (let i = nodes.length - 1; i >= 0; i--) {
        if (nodes[i].offsetTop - 100 < this.scrollPos) {
          let current = this.props.data.wordpressPost
          if (i !== 0) current = this.state.articles.appended[i - 1]
          if (typeof current === 'undefined') break

          let url = `/article/${current.slug}/`

          if (window.location.pathname.indexOf('/article') === -1) {
            return window.removeEventListener('scroll', this._scroll)
          }

          if (window.location.pathname !== url) {
            window.history.replaceState(window.history.state, '', url)
          }

          break
        }
      }

      if (this.scrollPos >= height - window.innerHeight - footer) {
        let { articles } = this.state
        if (articles.initial.length && articles.appended.length < ARTICLE_LIMIT) {
          articles.appended.push(articles.initial.pop().node)
          this.setState({ articles })
        }
      }

      this.ticking = false
    })

    this.ticking = true
  }

  _renderPost = (data, i=0) => {
    let backgroundImage = data.acf.featured_image.url
    let author = data.acf.author && data.acf.author.length ? data.acf.author[0] : {}
    let profile_picture = author && author.acf && author.acf.profile_picture && author.acf.profile_picture.url
    let back = () => {
      window.history.back()
    }
    let { category } = data.acf
    if (data.acf.sponsored) category = 'Sponsored'

    return (
      <article className="article" key={i}>
        <div className='article__header'>
          <div className='article__header__image' style={{ backgroundImage: `url(${backgroundImage})` }} />
          <div className='article__header__title'>
            <div className='article__header__title__inner'>
              <Tag category={ category } />
              <h1>{ he.decode(data.title) }</h1>
              <div className='article__header__info'>
                { author.post_name !== 'blank-author' && typeof author.post_name !== 'undefined' &&
                  <div className='article__header__author'>
                    <Link to={`/author/${author.post_name}`}>
                      <picture><img src={profile_picture} alt={ author.post_title } /></picture>
                      By { author.post_title }.
                    </Link>
                  </div>
                }
                <div className='article__header__share'>
                  <Share color={'#000000'} />
                  {data.acf.shares} shares
                </div>
                <div className='article__header__back' onClick={back}>« Back</div>
              </div>
            </div>
          </div>
        </div>
        <div className='article__content'>
          <ArticleSocial url={this.state.url} {...data} />
          <div className='article__inner'>
            { data.acf.content_blocks_post && data.acf.content_blocks_post.map((el, i) => {
              return this.renderBlock(el.__typename, el, i)
            }) }
            <div className="article__tags">
              { data.tags &&
                <ul>
                  { data.tags.map((el, i) => <li key={i}><Link to={`/search?query=${el.name}`}>{el.name}</Link></li>) }
                </ul>
              }
            </div>
            <div className="article__mobile-share">
              <ArticleMobileSocial url={this.state.url} {...data} />
            </div>
          </div>
        </div>
      </article>
    )
  }

  render() {
    let post = this.props.data.wordpressPost
    let featured_media = post.acf.featured_image
    return (
      <>
        <Helmet>
          <body className={`${post.acf.image_is_dark && 'header--lhs-dark'}`} />
          <title>{ he.decode(post.yoast_meta.yoast_wpseo_title) }</title>
          <meta name="description" content={post.yoast_meta.yoast_wpseo_metadesc} />
          <meta property="og:title" content={ he.decode(post.yoast_meta.yoast_wpseo_title) } />
          <meta property="og:description" content={ post.yoast_meta.yoast_wpseo_metadesc } />
          <meta property="twitter:title" content={ he.decode(post.yoast_meta.yoast_wpseo_title) } />
          <meta property="twitter:description" content={ post.yoast_meta.yoast_wpseo_metadesc } />
          <meta property="og:type" content="article" />
          { featured_media && [
            <meta property="og:image" content={featured_media.url} key={0} />,
            <meta property="og:image:width" content={featured_media.width} key={1} />,
            <meta property="og:image:height" content={featured_media.height} key={2} />,
            <meta property="og:image:url" content={featured_media.url} key={3} />,
          ]}
        </Helmet>
        { this._renderPost(post) }
        { this.state.articles.appended.map(this._renderPost) }
      </>
    )
  }

}

export default Article

export const articleQuery = graphql`
  query ($id: String!, $category: String!) {
    sitePlugin(name: {eq:"gatsby-source-wordpress"}) {
      pluginOptions {
        protocol
        baseUrl
      }
    }
    wordpressPost(id: {eq: $id}) {
      id
      wordpress_id
      title
      slug
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      tags {
        name
        slug
      }
      acf {
        category
        image_is_dark
        author {
          post_name
          post_title
          acf {
            profile_picture {
              url
              alt
            }
          }
        }
        type
        shares
        featured_image {
          url
          alt
        }
        content_blocks_post {
          __typename
          ... on WordPressAcf_content {
            content
          }
          ... on WordPressAcf_image {
            image {
              url
              alt
            }
            caption
          }
          ... on WordPressAcf_blockquote {
            blockquote
          }
          ... on WordPressAcf_video {
            video
          }
        }
      }
    }
    allWordpressPost(filter:{id:{ne:$id}, acf: {category: {eq: $category}}}, limit: 4)  {
      edges {
        node {
          id
          title
          slug
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
          tags {
            name
            slug
          }
          acf {
            category
            author {
              post_name
              post_title
              acf {
                profile_picture {
                  url
                  alt
                }
              }
            }
            type
            shares
            featured_image {
              url
              alt
              width
              height
            }
            content_blocks_post {
              __typename
              ... on WordPressAcf_content {
                content
              }
              ... on WordPressAcf_image {
                image {
                  url
                  alt
                }
                caption
              }
              ... on WordPressAcf_blockquote {
                blockquote
              }
              ... on WordPressAcf_video {
                video
              }
            }
          }
        }
      }
    }
  }
`
