import React, { Component } from 'react'

class Image extends Component {
  render() {
    let image = this.props.image && this.props.image.url
    return (
      <div className="post-block post-block--image">
        { image && <img className="post-block__image" src={image} alt={this.props.image.alt_text} /> }
        { this.props.caption &&
          <p className="post-block__caption">{this.props.caption}</p>
        }
      </div>
    )
  }
}

export default Image
