import React, { Component } from 'react'

class Content extends Component {
  render() {
    return (
      <div className={this.props.large_font ? 'post-block post-block--large' : 'post-block'}>
        <div className="post-block__content" dangerouslySetInnerHTML={{__html: this.props.content }} />
      </div>
    )
  }
}

export default Content
