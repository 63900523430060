import React, { Component } from 'react'

class Image extends Component {
  render() {
    return (
      <div className="post-block post-block--video">
        <div className="post-block__video-wrapper" dangerouslySetInnerHTML={{__html: this.props.video }} />
      </div>
    )
  }
}

export default Image
